<template>
    <tr>
        <td
            v-if="editTermReport"
        >
            <v-autocomplete
                v-model="recordSubject"
                :items="subjects"
                dense
                item-text="abbr"
                item-value="id"
                hide-details
                solo
                flat
                class="text-caption"
                @change="updateSubject"
                :readonly="!editTermReport"
                :filled="!editTermReport"            
            ></v-autocomplete>           
        </td>
        <td
            v-else
        >
            {{ record.abbr }}
        </td>        
        <td>
            {{ courseMark }}
        </td>
        <td>
            {{ examMark }}
        </td>
      
        <td
            class="text-caption"
        >
            {{ record.comment}}
        </td>
        <td>
            <v-btn 
                icon
                @click="deleteSubject"
                v-show="editTermReport"
            >
                <v-icon>
                    mdi-delete
                </v-icon>
            </v-btn>
        </td>
    </tr>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props: {
        record: Object,
    },
    mounted: function () {               
        this.recordSubject = this.record.subject_id;
    },
    watch: {
        record: {
            handler () {                
                this.recordSubject = this.record.subject_id;                
            }
        }
    },
    computed: {
        ...mapGetters({
            subjects: 'termReports/getSubjects',
            editTermReport: 'auth/getEditTermReport'
        }),
        courseMark(){
            if(this.record.course_attendance == 2){
                return 'Abs';
            }
            else if(this.record.course_attendance == 3){
                return 'NW';
            }
            return this.record.course_mark;
        },
        examMark(){
            if(this.record.exam_attendance == 2){
                return 'Abs';
            }
            return this.record.exam_mark;
        }
    },
    data: () => ({
        recordSubject: null,
    }),
    methods: {
        ...mapMutations({
            setDeletedTermMark: 'termReports/setDeletedTermMark',
            setOverlay: 'termReports/setOverlaySubjectRecords',
            setStudentTermMarks: 'termReports/setStudentTermMarks',
            setEditedTermMark: 'termReports/setEditedTermMark',
        }),
        ...mapActions({
            deleteStudentTermMark: 'termReports/deleteStudentTermMark',
            getStudentTermMarks: 'termReports/getStudentTermMarks',
            updateTermMarkSubject: 'termReports/updateTermMarkSubject',            
        }),
        async deleteSubject () {            
            this.setOverlay(true);           
            this.setDeletedTermMark(this.record);
            try {
                let response = await this.deleteStudentTermMark();
                console.log(response.status);
                response = await this.getStudentTermMarks();
                this.setStudentTermMarks(response.data);               
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.setOverlay(false);
        },
        async updateSubject () {
            this.setOverlay(true);             
            this.setEditedTermMark({
                student_id: this.record.student_id,
                academic_term_id: this.record.academic_term_id,
                prev_subject_id: this.record.subject_id,
                new_subject_id: this.recordSubject
            });
            try {
                let response = await this.updateTermMarkSubject();
                console.log(response.status);
                response = await this.getStudentTermMarks();
                this.setStudentTermMarks(response.data);   
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.setOverlay(false);
        }
    }
}
</script>

