<template>
    <v-container>
        <v-row
            
        >
            <v-col
                v-for="(item, i) in items"
                :key="i"
                class="flex-grow-0"                
            >
                <v-hover v-if="item.display">
                    <template v-slot:default=" { hover }">
                        <v-card
                            width="220"
                            height="150"
                            @click="display(item.option)"
                            elevation="4"
                            class="ma-4"
                        >
                            <v-card-title
                                class="d-flex justify-center pb-0"
                            >
                                <v-img
                                    :src="item.src"
                                    max-width="50"
                                    height="50"
                                    contain
                                    class="d-flex"
                                ></v-img>
                            </v-card-title>
                            <v-card-title 
                                class="subtitle-1 d-flex justify-center"
                            >
                                {{ item.title }}
                            </v-card-title>
                            <v-card-subtitle
                                class="caption d-flex justify-center"
                            >
                                {{ item.subtitle}}
                            </v-card-subtitle>
                            <v-fade-transition>
                                <v-overlay
                                    v-if="hover"
                                    absolute
                                    color="primary"
                                ></v-overlay>
                            </v-fade-transition>
                        </v-card>
                    </template>
                </v-hover>
            </v-col>    
        </v-row>
    </v-container>    
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    name: 'MainMenu',
    created(){
        this.initialization();
    },
    data: () => ({
        items: [
            {
                icon: 'mdi-keyboard',
                title: 'Enter Marks ',
                subtitle: '(Subject Teachers)',
                src: require("../assets/icons/enter-marks.png"),
                option: 'enter-marks',
                display: false
            },
            {
                icon: 'mdi-pencil',
                title: 'Edit/View Term Reports ',
                subtitle: '(Form Teachers)',
                src: require('../assets/icons/term-reports.png'),
                option: 'term-reports',
                display: false
            },
            {
                icon: 'mdi-pencil',
                title: 'Print/View Reports',
                subtitle: 'Print Report Cards, Mark Sheets, etc.',
                src: require('../assets/icons/print-view-reports.png'),
                option: 'print-view-reports',
                display: false
            },
            {
                icon: 'mdi-pencil',
                title: 'Employees',
                subtitle: 'Add / Edit Teachers, Timetables',
                src: require('../assets/icons/teacher.png'),
                option: 'employees',
                display: false
            },
            {
                icon: 'mdi-pencil',
                title: 'Student Subjects',
                subtitle: 'Form 4-6 Student Subjects ',
                src: require('../assets/icons/subject-assignment.png'),
                option: 'student-subjects',
                display: false
            },
            {
                icon: 'mdi-pencil',
                title: 'Students',
                subtitle: 'View/Edit Current Student Records',
                src: require('../assets/icons/edit-view-students.png'),
                option: 'edit-view-students',
                display: false
            },
            {
                icon: 'mdi-pencil',
                title: 'Reset Password',
                subtitle: 'Reset Employee Passwords',
                src: require('../assets/icons/reset-password.png'),
                option: 'reset-password',
                display: false
            },
            {
                icon: 'mdi-pencil',
                title: 'Settings',
                subtitle: '',
                src: require('../assets/icons/settings.png'),
                option: 'settings',
                display: false
            }, 
        ],
        menu: '',  
    }),
    computed: {
        ...mapGetters({
            user: 'auth/getEmployeeSignedIn',
            primaryColor: 'app/getPrimaryColor',
            colorDarken: 'app/getColorDarken',
            getMarkSheetLoading: 'termReports/getMarkSheetLoading',
            getTermReportsOverlay: 'termReports/getOverlay',            
            id: 'auth/getEmployeeId',
            userPermissions: 'auth/getPermissions',
        }),
        iconColor(){
            return this.primaryColor + ' ' + this.colorDarken;
        },
        titleClass(){
            return 'subtitle-1 font-weight-light font-italic ' + this.primaryColor + '--text text--' + this.colorDarken;
        },
        cardTitleClass(){
            return 'py-2 pl-8 caption white--text font-weight-light text-uppercase ' + this.primaryColor + ' ' + this.colorDarken;
        },
        fontClass(){
            return 'font-weight-normal text-uppercase ' + this.primaryColor + '--text text--' + this.colorDarken;
        }
    },
    watch: {
        //
    },
    methods: {
        ...mapMutations({
            mainMenu: 'mainMenu/setMainMenu',
            markSheet: 'mainMenu/setMarkSheet',
            termReports: 'mainMenu/setTermReports',
            employees: 'mainMenu/setEmployees',
            reports: 'mainMenu/setReports',
            studentSubjects: 'mainMenu/setStudentSubjects',
            editViewStudents: 'mainMenu/setEditViewStudents',
            resetPassword: 'mainMenu/setResetPassword',
            welcomeMessage: 'mainMenu/setWelcomeMessage',
            markSheetOverlay: 'markSheet/setMarkSheetOverlay',            
            markSheetLoading: 'markSheet/setMarkSheetLoading',
            setEditTermMark: 'auth/setEditTermMark',
            settings: 'mainMenu/setSettings',                      
        }),               
       
        display(option){
            //console.log(option);
            this.welcomeMessage(false);
            switch(option){
                case 'enter-marks':
                    this.showMenu('enter-marks');
                    break;
                case 'term-reports':
                    this.showMenu('term-reports');
                    break;
                case 'employees':                    
                    this.showMenu('employees');
                    break
                case 'print-view-reports':                   
                    this.showMenu('print-view-reports');
                    break               
                case 'student-subjects':                    
                    this.showMenu('student-subjects');
                    break               
                case 'edit-view-students':                   
                    this.showMenu('edit-view-students');
                    break               
                case 'reset-password':                    
                    this.showMenu('reset-password');
                    break               
                case 'settings':                    
                    this.showMenu('settings');
                    break               
            }
        },

        initialization(){
            let userPermissions = JSON.parse(sessionStorage.getItem('permissions'));
            // console.log(this.userPermissions);
            userPermissions.forEach(permission => {
                if(permission.permission_id < 9){
                    this.items[permission.permission_id - 1].display = true;
                }
                else if(permission.permission_id == 9){
                    this.setEditTermMark(true);
                    console.log("edit term mark permission");
                }                
            })
        },

        showMenu(menu){            
            this.mainMenu(false);
            this.markSheet(false);
            this.termReports(false);
            this.employees(false);
            this.reports(false);
            this.studentSubjects(false);
            this.editViewStudents(false);
            this.resetPassword(false);
            this.settings(false);            
            if(menu == 'enter-marks') this.markSheet(true);
            if(menu == 'term-reports') this.termReports(true);
            if(menu == 'employees') this.employees(true);
            if(menu == 'print-view-reports') this.reports(true);
            if(menu == 'student-subjects') this.studentSubjects(true);
            if(menu == 'edit-view-students') this.editViewStudents(true);
            if(menu == 'reset-password') this.resetPassword(true);
            if(menu == 'settings') this.settings(true);
        }

    }
}
</script>